import React from "react";
import "./Header.css";
import { GiGhost } from "react-icons/gi";

const Header = () => {
  // Check if the current path is "/"
  if (window.location.pathname === "/") {
    return null; // Return nothing if the pathname is "/"
  }

  return (
    <header>
      <div className="brand-name">
        <GiGhost size={40} color="white" />
        Ghost Lookup
      </div>
      <nav>
        <ul>
          <li>
            <a href="/generate">Generator</a>
          </li>
          <li>
            <a href="/validate">Validator</a>
          </li>
          <li>
            <a href="/send">Sender</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
