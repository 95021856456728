import React, { useState } from 'react';
import axios from 'axios';
import "./Generator.css"
import toast from "react-hot-toast"

const GenerateNumbers = () => {
  const [areaCodes, setAreaCodes] = useState('');
  const [count, setCount] = useState('');
  const [generatedNumbers, setGeneratedNumbers] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
     
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/phone-lookup/generate`, { areaCodes, count });
      setGeneratedNumbers(response.data.numbers);
    } catch (error) {
      console.error('Error generating numbers:', error);
      // Handle error state if needed
    }
  };

  const copyToClipboard = () => {
    const numbersText = generatedNumbers.join('\n');
    navigator.clipboard.writeText(numbersText)
      .then(() => {
        toast.success('Generated numbers copied to clipboard!');
      })
      .catch((error) => {
        toast.error('Failed to copy generated numbers to clipboard.');
      });
  };

  const downloadTxtFile = () => {
    const numbersText = generatedNumbers.join('\n');
    const blob = new Blob([numbersText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'generated_numbers.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadCsvFile = () => {
    const csvContent = generatedNumbers.map(number => [number]).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'generated_numbers.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className='generator'>
      <div className='left'>
      <h2>Generate Phone Numbers</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Area Codes (comma separated):
          <input
            type="text"
            value={areaCodes}
            onChange={(e) => setAreaCodes(e.target.value)}
            required
            placeholder='978, 872, 778, ...'
          />
        </label>
        <br />
        <label>
          Count:
          <input
            type="number"
            value={count}
            onChange={(e) => setCount(e.target.value)}
            required
            placeholder='Total numbers to be generated'
          />
        </label>
        <br />
        <button type="submit">Generate Numbers</button>
      </form>
      </div>

<div className='right'>
      {generatedNumbers.length > 0 && (
        <div>
          <h3>Generated Phone Numbers:</h3>
          <div className='btns'>
          <button onClick={copyToClipboard}>Copy to Clipboard</button>
          <button onClick={downloadTxtFile}>Download as TXT</button>
          <button onClick={downloadCsvFile}>Download as CSV</button>
          </div>
          <ul>
            {generatedNumbers.map((number, index) => (
              <li key={index}>{number}</li>
            ))}
          </ul>

        </div>
      )}
      </div>
    </div>
  );
};

export default GenerateNumbers;
