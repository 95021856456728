import React from "react";
import { GiGhost } from "react-icons/gi";
import "../App.css";
const Home = () => {
  return (
    <>
      <div
        style={{
          minHeight: "50vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GiGhost size={200} color="white" className="App-logo" />
      </div>
      <div>
        <ul
          style={{
            listStyleType: "none",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          <li>
            <a
              href="/generate"
              style={{
                border: "1px solid white",
                borderRadius: ".3em",
                padding: ".5rem",
                color: "white",
                textDecoration: "none",
              }}
            >
              Generate
            </a>
          </li>
          <li>
            <a
              href="/validate"
              style={{
                border: "1px solid white",
                borderRadius: ".3em",
                padding: ".5rem",
                color: "white",
                textDecoration: "none",
              }}
            >
              Validate
            </a>
          </li>
          <li>
            <a
              href="/send"
              style={{
                border: "1px solid white",
                borderRadius: ".3em",
                padding: ".5rem",
                color: "white",
                textDecoration: "none",
              }}
            >
              Sender
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Home;
