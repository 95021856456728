import React, { useState } from "react";
import axios from "axios";
import "./Send.css";
import { toast } from "react-hot-toast";

const EmailSender = () => {
  const [emails, setEmails] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [delaySeconds, setDelaySeconds] = useState(30);
  const [file, setFile] = useState(null);
  const [sentCount, setSentCount] = useState(0); // Tracks the number of emails sent
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handle file upload and update email input with file content
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/plain") {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        const fileEmails = reader.result
          .split("\n")
          .map((email) => email.trim())
          .filter((email) => email); // Remove empty lines
        setEmails(fileEmails.join(", ")); // Populate the email input field with the parsed emails
      };
      reader.readAsText(selectedFile);
    } else {
      setResponseMessage("Please upload a valid .txt file.");
    }
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    setEmails(e.target.value);
  };

  // Handle subject input change
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  // Handle message input change
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  // Handle delay input change
  const handleDelayChange = (e) => {
    setDelaySeconds(e.target.value);
  };

  // Parse emails from the file (assuming it's a .txt file with one email per line)
  const parseFileEmails = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const emailList = reader.result
          .split("\n")
          .map((email) => email.trim())
          .filter((email) => email);
        resolve(emailList);
      };
      reader.onerror = () => reject("Error reading the file.");
      reader.readAsText(file);
    });
  };

  // Form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseMessage("");
    setSentCount(0); // Reset sent count on each submission

    // Split the emails from the text input (if any) and remove any extra spaces
    let emailList = emails.split(",").map((email) => email.trim());

    // If a file is uploaded, parse the emails from the file
    if (file) {
      try {
        emailList = await parseFileEmails(file);
      } catch (error) {
        setResponseMessage("Error reading the file.");
        setIsLoading(false);
        return;
      }
    }

    // Basic validation
    if (emailList.length === 0 || !message) {
      setResponseMessage(
        "Please provide at least one email, a subject, and a message."
      );
      setIsLoading(false);
      return;
    }

    const totalEmails = emailList.length; // Total emails to be sent

    // Prepare the email data to send to the backend
    const emailData = {
      emails: emailList,
      subject: subject,
      message: message,
      delaySeconds: delaySeconds,
    };

    // Send emails one by one with the specified delay
    for (let i = 0; i < emailList.length; i++) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/phone-lookup/send-message`,
          emailData
        );

        if (response.data.success) {
          setSentCount((prevCount) => prevCount + 1); // Increment sent count
        } else {
          setResponseMessage(`Failed to send email to ${emailList[i]}.`);
          break;
        }
      } catch (error) {
        console.error(error);
        setResponseMessage("An error occurred while sending the email.");
        break;
      }
    }

    if (sentCount === totalEmails) {
      toast.success("All emails sent successfully!");
      setResponseMessage("All emails sent successfully!");
    }
    setIsLoading(false);
  };

  return (
    <div className="email-sender">
      <h1>Send Email</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="emails">Recipient Email(s):</label>
          <input
            type="text"
            id="emails"
            value={emails}
            onChange={handleEmailChange}
            placeholder="Enter emails separated by commas"
          />
        </div>

        <div>
          <label htmlFor="file">Upload Email List File:</label>
          <input type="file" id="file" onChange={handleFileChange} />
        </div>

        <div>
          <label htmlFor="subject">Subject:(optional)</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={handleSubjectChange}
            placeholder="Enter email subject"
          />
        </div>

        <div>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={handleMessageChange}
            placeholder="Enter email message"
          />
        </div>

        <div>
          <label htmlFor="delay">Delay (in seconds between emails):</label>
          <input
            type="number"
            id="delay"
            value={delaySeconds}
            onChange={handleDelayChange}
            placeholder="Enter delay in seconds"
          />
        </div>

        <button type="submit" disabled={isLoading}>
          {isLoading ? "Sending..." : "Send Email"}
        </button>
      </form>

      {responseMessage && (
        <div className="response-message">
          <p>{responseMessage}</p>
        </div>
      )}

      {/* Display the email sent count */}
      <div className="sent-count">
        <p>
          Emails sent: {sentCount} / {emails.split(",").length}
        </p>
      </div>
    </div>
  );
};

export default EmailSender;
