import React, { useState } from 'react';
import axios from 'axios';
import toast from "react-hot-toast";
import "./Validator.css";

const Validator = () => {
    const [phoneNumbers, setPhoneNumbers] = useState('');
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [uploadedFileContent, setUploadedFileContent] = useState('');
    const [uploadedFileDisplay, setUploadedFileDisplay] = useState('');
    const [validationStatus, setValidationStatus] = useState([]); // Track validation status

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');
        setResults([]);
        console.log(validationStatus);
        
        try {
            let numbers;
            if (uploadedFileContent) {
                numbers = uploadedFileContent.split(/[,\n;]/).map(num => num.trim()).filter(num => num.length > 0);
                setPhoneNumbers(uploadedFileContent);
            } else {
                numbers = phoneNumbers.split(',').map(num => num.trim());
            }

            if (numbers.length === 0) {
                throw new Error("Please enter at least one phone number.");
            }

            // Initialize the validation status list with all numbers as "validating"
            setValidationStatus(numbers.map(num => ({ number: num, status: 'validating' })));

            // Validate each number sequentially
            for (let i = 0; i < numbers.length; i++) {
                const currentNumber = numbers[i];
                await validateNumber(currentNumber);
            }
        } catch (error) {
            console.error('Error validating numbers:', error);
            setError(error.message || 'An error occurred during validation.');
        } finally {
            setIsLoading(false);
        }
    };

    const validateNumber = async (phoneNumber) => {
        try {
            // Update the status to "validating" for the current number
            setValidationStatus((prevStatus) => 
                prevStatus.map(item => 
                    item.number === phoneNumber ? { ...item, status: 'validating' } : item
                )
            );

            // Send the request to the backend
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/phone-lookup/validate`, { phoneNumbers: phoneNumber });

            // Add the result to the validated numbers list
            const result = response.data.results[0];

            // Update the status to "validated"
            setValidationStatus((prevStatus) => 
                prevStatus.map(item => 
                    item.number === phoneNumber ? { ...item, status: 'validated', result } : item
                )
            );
            setResults((prev) => [...prev, result]); // Optionally, you can store the results in a separate array
        } catch (error) {
            console.error('Error validating number:', error);
            setValidationStatus((prevStatus) => 
                prevStatus.map(item => 
                    item.number === phoneNumber ? { ...item, status: `Error: ${error.message}` } : item
                )
            );
        }
    };

    const copyToClipboard = () => {
        const resultsText = results.join('\n');
        navigator.clipboard.writeText(resultsText)
            .then(() => {
                toast.success('Results copied to clipboard!');
            })
            .catch((error) => {
                toast.error('Failed to copy results to clipboard.');
            });
    };

    const downloadTxtFile = () => {
        const resultsText = results.join('\n');
        const blob = new Blob([resultsText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'validation_results.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const downloadCsvFile = () => {
        const csvContent = results.map(result => `"${result.replace(/"/g, '""')}"`).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'validation_results.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            const lines = content.split('\n').map(line => line.trim()).filter(line => line.length > 0);
            setUploadedFileContent(lines.join(',')); // Join valid lines with commas
            setUploadedFileDisplay(lines.join(', ')); // Display content with commas and spaces
        };
    
        reader.onerror = () => {
            setError('Failed to read the file.');
        };
    
        reader.readAsText(file);
    };

    return (
        <div className='generator'>
            <div className='left'>
                <h2>Validate Phone Numbers</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Enter Phone Numbers (comma-separated):
                        <input
                            type="text"
                            name='phoneNumbers'
                            value={uploadedFileContent ? uploadedFileDisplay : phoneNumbers}
                            onChange={(e) => setPhoneNumbers(e.target.value)}
                            required
                        />
                    </label>
                    <div id='file-container'>
                        <h3>Or Upload a File:</h3>
                        <input type="file" accept=".txt,.csv" onChange={handleFileUpload} />
                    </div>
                    <button type="submit" disabled={isLoading}>Validate</button>
                </form>
            </div>

            <div className='right'>
                <h3>Validation Status:</h3>
                <ul>
                    {validationStatus.map((statusItem, index) => (
                        <li key={index}>
                            {statusItem.number} - <strong>{statusItem.status}</strong>
                        </li>
                    ))}
                </ul>

                {results.length > 0 && (
                    <div className='btns'>
                        <button onClick={copyToClipboard}>Copy Results</button>
                        <button onClick={downloadTxtFile}>Download as TXT</button>
                        <button onClick={downloadCsvFile}>Download as CSV</button>
                    </div>
                )}
                <h3>Results:</h3>
                <ul>
                    {results.map((result, index) => (
                        <li key={index}>{result}</li>
                    ))}
                </ul>
            </div>

            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default Validator;
