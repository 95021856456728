import React from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => navigate("/home")}>
        Login
      </div>
      <div style={{ color: "#eee", marginTop: "350px" }}>Loading...</div>
    </>
  );
};

export default Login;
